import React from 'react';

export const icon = (
  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0277 2.94051H8.82867C8.0153 2.94051 7.35571 2.28213 7.35571 1.47025C7.35571 0.65838 8.0153 0 8.82867 0H19.028C19.8414 0 20.5006 0.65838 20.5006 1.47025C20.5006 2.28213 19.8411 2.94051 19.0277 2.94051ZM1.47295 4.7915H26.3838C27.1972 4.7915 27.8568 5.44988 27.8568 6.26176C27.8568 7.07363 27.1972 7.73201 26.3838 7.73201H24.8782V26.1419C24.8782 28.2691 23.1445 29.9996 21.0135 29.9996H6.79798C4.66691 29.9996 2.93324 28.2691 2.93324 26.1419V7.73201H1.47295C0.659589 7.73201 0 7.07363 0 6.26176C0 5.44988 0.659589 4.7915 1.47295 4.7915ZM21.0138 27.0591C21.5204 27.0591 21.9326 26.6477 21.9326 26.1419V7.73201H5.87944V26.1419C5.87944 26.6477 6.29158 27.0591 6.79827 27.0591H21.0138ZM10.7945 24.1727C11.6079 24.1727 12.2674 23.5143 12.2674 22.7024V12.4771C12.2674 11.6652 11.6079 11.0068 10.7945 11.0068C9.98112 11.0068 9.32153 11.6652 9.32153 12.4771V22.7024C9.32153 23.5143 9.98112 24.1727 10.7945 24.1727ZM18.4 22.7024C18.4 23.5143 17.7404 24.1727 16.9271 24.1727C16.1137 24.1727 15.4541 23.5143 15.4541 22.7024V12.4771C15.4541 11.6652 16.1137 11.0068 16.9271 11.0068C17.7404 11.0068 18.4 11.6652 18.4 12.4771V22.7024Z"
      fill="#D0D5D8"
    />
  </svg>
);
